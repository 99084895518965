import React, { useEffect, useState, useMemo } from 'react'
import { useQuery } from '@apollo/client'
import { useGetClassName, useMakeTestId } from '@divvy-web/hooks.usenameformatter'
import { FormattedMessage } from '@divvy-web/i18n'
import { bool } from 'prop-types'
import { useParams } from 'react-router-dom'
import { FormCheckboxInput, FormDropdown, FormTextInput, useForm } from '@divvy-web/skylab.form'
import { CreditApplicationBusinessEntity, SOLE_PROPRIETORSHIP } from '_gql'
import BusinessFormationFields from '../../components/BusinessFormationFields'
import { MaskedFormInput } from '../../components'
import { AddressFormFields } from '../../components/AddressForm'
import FormElementWrapper from '../../components/FormInputs/FormElementWrapper'
import PhoneNumberInput from '../../components/FormInputs/PhoneNumberInput'
import {
  dateStringToEpoch,
  formatDateString,
  getFormattedDate,
  getFormattedEIN,
  getUSStates,
  normalizeSmartQuotesAndEmdashes,
} from '../../components/utils'
import { formStyles } from '../styles/applicationPageSharedStyles'
import GetBdcOrgId from '../gql/GetBdcOrgId.gql'
import { legalCharactersForPreferredNamePattern } from '../../utils/validationUtils'
import SignUpBusinessFields from '../SignUp/SignUpBusinessFields'
import { MAJOR_ACCOUNT_CHANGE, MIGRATION } from '../../resources/constants'
import useInitialBusinessValuesForMac from '../../hooks/useInitialBusinessValuesForMac'
import { useKeepNeoSessionAlive } from '../../hooks/useKeepNeoSessionAlive'
import FullNameInput from '../../components/FormInputs/FullNameInput'
import useGetBillProduct from '../../hooks/useGetBillProduct'
import { businessPageStyles, websiteFieldStyles } from './businessInfoStyles'

const BusinessInfoForm = ({ alwaysShowError, isReview, readOnly }) => {
  const { appId } = useParams()
  const { formValues, getFormValue, setFormValue, validationErrors } = useForm()
  const isFormValid = !validationErrors
  const [websiteFieldClicked, setWebsiteFieldClicked] = useState(false)
  const [pageLoaded, setPageLoaded] = useState(false)
  const { company, product } = useGetBillProduct()

  const { data, loading } = useQuery(GetBdcOrgId, {
    variables: {
      creditApplicationId: appId,
    },
  })

  useKeepNeoSessionAlive(data?.creditApplication?.bdcOrgId)

  const [getInitialMacBusinessValues] = useInitialBusinessValuesForMac(appId)

  const getClassName = useGetClassName('business-info-form')
  const makeTestId = useMakeTestId('business-info-form')
  const initialMacBusinessValues = getInitialMacBusinessValues()
  const {
    _recordType: recordType,
    adminFirstName,
    adminLastName,
    adminPreferredFullName,
    entityType,
    accountingSoftware,
    legalBusinessName,
    preferredAccountName,
    websiteFieldNotRequired,
  } = formValues

  const {
    mailingAddressCity,
    mailingAddressPostalCode,
    mailingAddressState,
    mailingAddressStreet,
    mailingAddressStreetAdditional,
    physicalAddressCity,
    physicalAddressPostalCode,
    physicalAddressState,
    physicalAddressStreet,
    physicalAddressStreetAdditional,
  } = formValues

  const physicalMailingAddressesEqual = useMemo(() => {
    if (physicalAddressStreet?.length && physicalAddressStreet !== mailingAddressStreet) return false
    if (physicalAddressStreetAdditional?.length && physicalAddressStreetAdditional !== mailingAddressStreetAdditional) {
      return false
    }
    if (physicalAddressCity?.length && physicalAddressCity !== mailingAddressCity) return false
    if (physicalAddressState?.length && physicalAddressState !== mailingAddressState) return false
    if (physicalAddressPostalCode?.length && physicalAddressPostalCode !== mailingAddressPostalCode) return false
    return true
  }, [
    mailingAddressCity,
    mailingAddressPostalCode,
    mailingAddressState,
    mailingAddressStreet,
    mailingAddressStreetAdditional,
    physicalAddressCity,
    physicalAddressPostalCode,
    physicalAddressState,
    physicalAddressStreet,
    physicalAddressStreetAdditional,
  ])

  const [mailingAddressDifferentChecked, setMailingAddressDifferentChecked] = useState(!physicalMailingAddressesEqual)

  const isAdminNameTooLong = adminFirstName?.length + adminLastName?.length > 21
  const showFullName = readOnly ? adminPreferredFullName?.length > 0 && isAdminNameTooLong : isAdminNameTooLong
  const shouldShowFullNameTooltip =
    !isReview &&
    (!adminPreferredFullName ||
      adminPreferredFullName?.length > 21 ||
      !getFormValue('_savedSections')?.includes('BUSINESS_INFO'))

  const isPrefillPreferredAccountName = Boolean(
    legalBusinessName &&
      !preferredAccountName &&
      new RegExp(legalCharactersForPreferredNamePattern).test(legalBusinessName),
  )

  useEffect(() => {
    if (!isAdminNameTooLong) setFormValue('adminPreferredFullName', null)
  }, [isAdminNameTooLong, setFormValue])

  useEffect(() => {
    if (!pageLoaded && isPrefillPreferredAccountName) {
      if (legalBusinessName.length > 21) {
        setFormValue('preferredAccountName', legalBusinessName.slice(0, 21))
      } else {
        setFormValue('preferredAccountName', legalBusinessName)
      }
    }
    setPageLoaded(true)
  }, [isPrefillPreferredAccountName, legalBusinessName, pageLoaded, setFormValue])

  const handlePrefillPreferredName = () => {
    if (isPrefillPreferredAccountName && legalBusinessName?.length <= 21) {
      setFormValue('preferredAccountName', legalBusinessName)
    }
  }

  const isSoleProprietorship = entityType === CreditApplicationBusinessEntity.SOLE_PROPRIETORSHIP
  const isBdcOrg = loading || !!data?.creditApplication?.bdcOrgId
  const isMac = recordType === MAJOR_ACCOUNT_CHANGE
  const isMigrationApp = recordType === MIGRATION
  const usStatesOptions = getUSStates()

  const preferredNameCaption =
    isReview || !!validationErrors?.['preferredAccountName'] ? null : (
      <FormattedMessage
        defaultMessage='This will be used for your {company} account and on your physical cards.'
        id='sputnik.BusinessInfoForm__B+9TPi'
        values={{ company }}
      />
    )

  const handleOptionWebsite = () => {
    setFormValue('websiteFieldNotRequired', !getFormValue('websiteFieldNotRequired'))
    setFormValue('website', '')
  }

  const handleWebsiteClick = () => {
    setWebsiteFieldClicked(true)
  }

  const mailingPhysicalAddressEqualCheckboxClick = () => {
    setMailingAddressDifferentChecked((v) => !v)
  }

  const shouldDisableField = (fieldName) => {
    return isMac && !!initialMacBusinessValues && !!initialMacBusinessValues[fieldName]
  }

  const shouldDisablePhysicalAddressFields =
    isMac &&
    initialMacBusinessValues &&
    !!initialMacBusinessValues['physicalAddressCity'] &&
    !!initialMacBusinessValues['physicalAddressPostalCode'] &&
    !!initialMacBusinessValues['physicalAddressState'] &&
    !!initialMacBusinessValues['physicalAddressStreet']

  const shouldDisableMailingAddressFields =
    isMac &&
    initialMacBusinessValues &&
    !initialMacBusinessValues['mailingAddressCity'] &&
    !initialMacBusinessValues['mailingAddressPostalCode'] &&
    !initialMacBusinessValues['mailingAddressState'] &&
    !initialMacBusinessValues['mailingAddressStreet']

  useEffect(() => {
    const validateMailAddressFormFields = getFormValue('validateMailAddressFormFields')
    if (mailingAddressDifferentChecked !== validateMailAddressFormFields) {
      setFormValue('validateMailAddressFormFields', mailingAddressDifferentChecked)
    }
  }, [getFormValue, mailingAddressDifferentChecked, setFormValue])

  return (
    <section css={(theme) => [formStyles({ isReview, readOnly }, theme), businessPageStyles(isReview, theme)]}>
      <div className='business-form-field-wrapper'>
        {isReview || (
          <div className='field-container'>
            <div className='business-info-section-text-admin fs-unmask'>
              <FormattedMessage
                defaultMessage='{product} admin'
                id='sputnik.BusinessInfoForm__GLl/NF'
                values={{ product }}
              />
            </div>
          </div>
        )}
        {isReview && (
          <SignUpBusinessFields
            isReview
            initialMacBusinessValues={initialMacBusinessValues}
            isMac={isMac}
            isPersonal={false}
            readOnly={readOnly}
          />
        )}
        {!isMigrationApp && (
          <div className='field-row fs-unmask'>
            <div className='field-container'>
              <FormElementWrapper>
                <FormTextInput
                  alwaysShowError={alwaysShowError}
                  autoComplete='none'
                  className='fs-mask'
                  dataTestId={makeTestId('admin-first-name')}
                  disabled={shouldDisableField('adminFirstName')}
                  formFieldClassName={getClassName('admin-first-name')}
                  label={
                    <FormattedMessage
                      defaultMessage='Admin first name'
                      id='sputnik.BusinessInfoForm__wuXXmf'
                    />
                  }
                  maxLength={30}
                  name='adminFirstName'
                  normalize={normalizeSmartQuotesAndEmdashes}
                  placeholder={
                    <FormattedMessage
                      defaultMessage='Admin first name'
                      id='sputnik.BusinessInfoForm__wuXXmf'
                    />
                  }
                  readOnly={readOnly}
                />
              </FormElementWrapper>
            </div>
            <div className='field-container'>
              <FormElementWrapper>
                <FormTextInput
                  alwaysShowError={alwaysShowError}
                  autoComplete='none'
                  className='fs-mask'
                  dataTestId={makeTestId('admin-last-name')}
                  disabled={shouldDisableField('adminLastName')}
                  formFieldClassName={getClassName('admin-last-name')}
                  label={
                    <FormattedMessage
                      defaultMessage='Admin last name'
                      id='sputnik.BusinessInfoForm__Z89MvP'
                    />
                  }
                  maxLength={30}
                  name='adminLastName'
                  normalize={normalizeSmartQuotesAndEmdashes}
                  placeholder={
                    <FormattedMessage
                      defaultMessage='Admin last name'
                      id='sputnik.BusinessInfoForm__Z89MvP'
                    />
                  }
                  readOnly={readOnly}
                />
              </FormElementWrapper>
            </div>
          </div>
        )}
        {showFullName && !isMigrationApp && (
          <div className='field-row'>
            <div className='field-container-full'>
              <FullNameInput
                isAdmin
                alwaysShowError={alwaysShowError}
                className='fs-mask'
                dataTestId={makeTestId('admin-preferred-full-name')}
                disabled={shouldDisableField('adminPreferredFullName')}
                formFieldClassName={getClassName('admin-preferred-full-name')}
                name='adminPreferredFullName'
                preferredFullName={adminPreferredFullName}
                readOnly={readOnly}
                showTooltipAtStart={shouldShowFullNameTooltip}
              />
            </div>
          </div>
        )}
        {!isMigrationApp && (
          <div className='field-row'>
            <div className='field-container fs-unmask'>
              <FormElementWrapper>
                <FormTextInput
                  alwaysShowError={alwaysShowError}
                  autoComplete='on'
                  className='fs-mask'
                  dataTestId={makeTestId('admin-email')}
                  disabled={shouldDisableField('adminEmail')}
                  formFieldClassName={getClassName('admin-email')}
                  label={
                    <FormattedMessage
                      defaultMessage='Admin work email'
                      id='sputnik.BusinessInfoForm__z4S4M1'
                    />
                  }
                  name='adminEmail'
                  placeholder={
                    <FormattedMessage
                      defaultMessage='Admin work email'
                      id='sputnik.BusinessInfoForm__z4S4M1'
                    />
                  }
                  readOnly={readOnly}
                  type='email'
                />
              </FormElementWrapper>
            </div>
            <div className='field-container'>
              <PhoneNumberInput
                alwaysShowError={alwaysShowError}
                dataTestId={makeTestId('admin-phone')}
                disabled={shouldDisableField('adminPhoneNumber')}
                inputId='adminPhoneNumber'
                label={
                  <FormattedMessage
                    defaultMessage='Admin work phone'
                    id='sputnik.BusinessInfoForm__5DmIuW'
                  />
                }
                readOnly={readOnly}
              />
            </div>
          </div>
        )}
        {isReview || (
          <div className='field-container'>
            <div className='business-info-section-text-main fs-unmask'>
              <FormattedMessage
                defaultMessage='About your business'
                id='sputnik.BusinessInfoForm__pHoxiK'
              />
            </div>
          </div>
        )}
        <div
          className='field-row fs-unmask'
          id='business-name-fields'
        >
          <div className='field-container'>
            <FormElementWrapper>
              <FormTextInput
                alwaysShowError={alwaysShowError}
                autoComplete='none'
                dataTestId={makeTestId('name')}
                disabled={shouldDisableField('legalBusinessName')}
                formFieldClassName={getClassName('name')}
                label={
                  <FormattedMessage
                    defaultMessage='Legal business name'
                    id='sputnik.BusinessInfoForm__U/+zt/'
                  />
                }
                name='legalBusinessName'
                normalize={normalizeSmartQuotesAndEmdashes}
                placeholder={
                  <FormattedMessage
                    defaultMessage='Enter business name'
                    id='sputnik.BusinessInfoForm__8pMM9q'
                  />
                }
                readOnly={readOnly}
                rightIcon={isMac && !legalBusinessName ? 'warningFilled' : null}
                onBlur={handlePrefillPreferredName}
              />
            </FormElementWrapper>
          </div>
          <div className='field-container'>
            <FormElementWrapper>
              <FormTextInput
                alwaysShowError={alwaysShowError}
                autoComplete='none'
                caption={preferredNameCaption}
                dataTestId={makeTestId('preferred-name')}
                disabled={shouldDisableField('preferredAccountName')}
                formFieldClassName={getClassName('preferred-name')}
                label={
                  <FormattedMessage
                    defaultMessage='Preferred business name'
                    id='sputnik.BusinessInfoForm__Hq6+4+'
                  />
                }
                maxLength={21}
                name='preferredAccountName'
                normalize={normalizeSmartQuotesAndEmdashes}
                placeholder={
                  <FormattedMessage
                    defaultMessage='Preferred business name'
                    id='sputnik.BusinessInfoForm__Hq6+4+'
                  />
                }
                readOnly={readOnly}
                rightIcon={readOnly ? null : <span>{preferredAccountName ? preferredAccountName.length : 0}/21</span>}
              />
            </FormElementWrapper>
          </div>
        </div>
        <div
          className='field-row fs-unmask'
          id='business-website-fields'
        >
          <div
            className='field-container'
            css={websiteFieldStyles({ isFormValid, websiteFieldClicked })}
          >
            <div className='website-field-wrapper'>
              <FormElementWrapper>
                {readOnly && !getFormValue('website') ? (
                  <FormTextInput
                    dataTestId={makeTestId('website-blank')}
                    disabled={isMac}
                    label={
                      <FormattedMessage
                        defaultMessage='Business website'
                        id='sputnik.BusinessInfoForm__go2n+4'
                      />
                    }
                    name='__websiteBlank'
                    placeholder={
                      <FormattedMessage
                        defaultMessage='Business website'
                        id='sputnik.BusinessInfoForm__go2n+4'
                      />
                    }
                    readOnly={readOnly}
                    value='-'
                  />
                ) : (
                  <FormTextInput
                    alwaysShowError={alwaysShowError}
                    autoComplete='none'
                    className={getClassName('website')}
                    dataTestId={makeTestId('website')}
                    disabled={websiteFieldNotRequired || shouldDisableField('website')}
                    label={
                      <FormattedMessage
                        defaultMessage='Business website'
                        id='sputnik.BusinessInfoForm__go2n+4'
                      />
                    }
                    name='website'
                    placeholder={
                      <FormattedMessage
                        defaultMessage='Business website'
                        id='sputnik.BusinessInfoForm__go2n+4'
                      />
                    }
                    readOnly={readOnly}
                    onBlur={handleWebsiteClick}
                  />
                )}
              </FormElementWrapper>
            </div>
            {isReview || (
              <FormCheckboxInput
                autoComplete='none'
                checkboxLabel={
                  <FormattedMessage
                    defaultMessage='I do not have a business website'
                    id='sputnik.BusinessInfoForm__c4XkfH'
                  />
                }
                dataTestId={makeTestId('not-required-website-toggle')}
                disabled={isMac}
                formFieldClassName={getClassName('not-required-website-toggle')}
                name='websiteFieldNotRequired'
                readOnly={readOnly}
                onChange={() => handleOptionWebsite()}
              />
            )}
          </div>
          <div className='field-container fs-unmask'>
            <MaskedFormInput
              alwaysShowError={alwaysShowError}
              className='fs-mask'
              dataTestId={makeTestId('tax-id')}
              disabledForMac={shouldDisableField('taxId')}
              formFieldClassName={getClassName('tax-id')}
              inputMode='numeric'
              isMac={isMac}
              label={
                <FormattedMessage
                  defaultMessage='Business tax ID / EIN #'
                  id='sputnik.BusinessInfoForm__KG5gxW'
                />
              }
              maxLength={isSoleProprietorship ? 11 : 10}
              metadata={{ isSsn: isSoleProprietorship }}
              name='taxIdDisplay'
              normalize={(value, masked) => getFormattedEIN(value, masked, isSoleProprietorship)}
              pathName='businessInfo'
              placeholder={isSoleProprietorship ? 'Business EIN #' : 'Business tax ID'}
              readOnly={readOnly}
              unmaskButtonDisabled={isBdcOrg}
            />
          </div>
        </div>
        <div className='field-row fs-unmask'>
          <BusinessFormationFields
            alwaysShowError={alwaysShowError}
            readOnly={readOnly}
            shouldDisableField={shouldDisableField}
          />
        </div>
        <AddressFormFields
          alwaysShowError={alwaysShowError}
          businessName={legalBusinessName}
          className={getClassName('physical-address')}
          dataTestId={makeTestId('physical-address')}
          disabled={shouldDisablePhysicalAddressFields}
          label={
            <FormattedMessage
              defaultMessage='Business physical address'
              id='sputnik.BusinessInfoForm__4IvIJZ'
            />
          }
          labelLine2={
            <FormattedMessage
              defaultMessage='Business physical address line 2'
              id='sputnik.BusinessInfoForm__fJQGjR'
            />
          }
          pageName='physicalAddress'
          placeholder={
            <FormattedMessage
              defaultMessage='Enter street address'
              id='sputnik.BusinessInfoForm__gU60u1'
            />
          }
          readOnly={readOnly}
          stateOptions={usStatesOptions}
        />
        {readOnly || (
          <div className='field-container-full fs-unmask'>
            <FormCheckboxInput
              autoComplete='none'
              checkboxLabel={
                <FormattedMessage
                  defaultMessage='Mailing address is different than physical address'
                  id='sputnik.BusinessInfoForm__9e19+K'
                />
              }
              checked={!!mailingAddressDifferentChecked}
              dataTestId={makeTestId('mailing-address-toggle')}
              disabled={shouldDisableMailingAddressFields && shouldDisablePhysicalAddressFields}
              formFieldClassName={getClassName('mailing-address-toggle')}
              name='mailingPhysicalAddressesEqual'
              readOnly={readOnly}
              onChange={mailingPhysicalAddressEqualCheckboxClick}
            />
          </div>
        )}
        {mailingAddressDifferentChecked && (
          <AddressFormFields
            businessName={legalBusinessName}
            className={getClassName('mailing-address')}
            dataTestId={makeTestId('mailing-address')}
            disabled={shouldDisableMailingAddressFields}
            label={
              <FormattedMessage
                defaultMessage='Business mailing address'
                id='sputnik.BusinessInfoForm__NOd4Sq'
              />
            }
            labelLine2={
              <FormattedMessage
                defaultMessage='Business mailing address line 2'
                id='sputnik.BusinessInfoForm__egiys+'
              />
            }
            pageName='mailingAddress'
            placeholder={
              <FormattedMessage
                defaultMessage='Enter street address'
                id='sputnik.BusinessInfoForm__gU60u1'
              />
            }
            readOnly={readOnly}
            stateOptions={usStatesOptions}
          />
        )}
      </div>
    </section>
  )
}

BusinessInfoForm.propTypes = {
  alwaysShowError: bool,
  isReview: bool,
  readOnly: bool,
}

export default BusinessInfoForm
