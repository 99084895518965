import { curry } from '@divvy-web/utils'
import { css } from '@emotion/core'
import { bool, node, string } from 'prop-types'
import React from 'react'
import { useCanary } from '@bill/canary.react'
import CustomerAssistanceButton from '../CustomerAssistanceButton/CustomerAssistanceButton'
import DashboardHeading from '../DashboardHeading/DashboardHeading'
import ReviewsSideSection from '../ReviewsSideSection/ReviewsSideSection'
import TypesOfCreditInfoBox from '../../pages/GoodFitPage/TypesOfCreditInfoBox'

const PageViewWrapper = ({ bdcOrgId, canStartNewApplication, children, dsaVersion, inviteCollaborators, pageName }) => {
  //TODO: convert all these strings to constants
  const isGoodFitPage = pageName.toLowerCase() === 'goodfit'
  const isMigrationSuccess = pageName.toLowerCase() === 'migrationsuccess'
  const isDivvyProgramFitPage = pageName.toLowerCase() === 'divvyprogramfit'
  const isSignin = pageName.toLowerCase() === 'signin'
  const isNotGoodFit = pageName.toLowerCase() === 'notgoodfit'
  const isDashboard = pageName === 'dashboard'
  const isReviewAndSign = pageName.toLowerCase() === 'reviewandsign'
  const isApplicationSubmitted = pageName.toLowerCase() === 'application-submitted'
  const isSecuredLine = useCanary('show-secured-line')
  const isStateBasedDisclosures = useCanary('state-based-disclosures')
  const showSecuredLineGoodFitPage = isSecuredLine || isStateBasedDisclosures

  const shouldHaveOneColorWidth = isGoodFitPage || isNotGoodFit || isSignin || isReviewAndSign

  if (isDashboard || isMigrationSuccess) {
    return (
      <div
        className='dashboard-wrapper'
        data-testid='dashboard-page'
      >
        <CustomerAssistanceButton shouldUseSpecialMobileStyles={false} />
        <DashboardHeading
          bdcOrgId={bdcOrgId}
          canStartNewApplication={canStartNewApplication}
          isMigrationSuccess={isMigrationSuccess}
        />
        <div css={dashboardStyles}>{children}</div>
      </div>
    )
  }
  return (
    <div
      css={pageViewWrapperStyles({
        isApplicationSubmitted,
        isDivvyProgramFitPage,
        isGoodFitPage,
        isNotGoodFit,
        isSignin,
        shouldHaveOneColorWidth,
      })}
    >
      <div className='main-page-wrapper'>
        <CustomerAssistanceButton shouldUseSpecialMobileStyles />
        {children}
      </div>
      {!showSecuredLineGoodFitPage && (
        <div className='reviews-side-section-wrapper'>
          <ReviewsSideSection
            dsaVersion={dsaVersion}
            inviteCollaborators={inviteCollaborators}
            pageName={pageName}
          />
        </div>
      )}
      {showSecuredLineGoodFitPage &&
        (isGoodFitPage ? (
          <TypesOfCreditInfoBox />
        ) : (
          <div className='reviews-side-section-wrapper'>
            <ReviewsSideSection
              dsaVersion={dsaVersion}
              inviteCollaborators={inviteCollaborators}
              pageName={pageName}
            />
          </div>
        ))}
    </div>
  )
}

export default PageViewWrapper

const pageViewWrapperStyles = curry(
  (
    { isApplicationSubmitted, isDivvyProgramFitPage, isGoodFitPage, isNotGoodFit, isSignin, shouldHaveOneColorWidth },
    { mq },
  ) => css`
    display: flex;
    min-height: 100vh;
    max-height: 100vh;
    gap: 0;
    ${mq.xSmallMaxWidth({ maxHeight: 'auto', minHeight: 'auto' })}
    ${isGoodFitPage && mq.xSmallMaxWidth({ display: 'block' })}

    .reviews-side-section-wrapper {
      width: ${shouldHaveOneColorWidth
        ? '35%'
        : isSignin || isApplicationSubmitted || isDivvyProgramFitPage || isGoodFitPage || isNotGoodFit
        ? '40%'
        : '25%'};
      min-height: 100vh;
      overflow-y: auto;
      background-color: var(--tri-color-fill-accent-primary);
      max-width: ${isGoodFitPage && '960px'};

      ${!isSignin && !isGoodFitPage && !isApplicationSubmitted && mq.largeMaxWidth({ display: 'none' })};
      ${shouldHaveOneColorWidth && isGoodFitPage && mq.largeMaxWidth({ display: 'none' })};
      ${isGoodFitPage && mq.mediumMaxWidth({ minWidth: '240px' })}
      ${(isSignin || isApplicationSubmitted || isGoodFitPage) && mq.xSmallMaxWidth({ display: 'none' })};
    }

    .main-page-wrapper {
      overflow: ${!isGoodFitPage && 'auto'};
      overflow-x: ${isGoodFitPage && 'visible'};
      width: ${isGoodFitPage ? '60%' : '75%'};
      flex-grow: ${isGoodFitPage && 2};
      ${mq.largeMaxWidth({ width: isGoodFitPage ? '60%' : '100%' })};
      ${mq.xSmallMaxWidth({ overflow: 'visible', width: '100%' })}
    }

    .review-wrap:nth-of-type(2) {
      margin-right: ${isGoodFitPage && 'var(--tri-space-500)'};
      ${isGoodFitPage && mq.xLargeMaxWidth({ marginRight: 0 })}
    }

    .review-wrap:nth-of-type(3) {
      margin-right: ${isGoodFitPage && 'var(--tri-space-100)'};
      ${isGoodFitPage && mq.xSmallMaxWidth({ overflow: 'visible', width: '100%' })}
      ${isGoodFitPage && mq.xLargeMaxWidth({ marginRight: 0 })}
    }

    .review {
      border-radius: ${isGoodFitPage && '16px'};
    }
  `,
)

const dashboardStyles = ({ mq }) => css`
  padding-bottom: var(--tri-space-1200);
  margin: var(--tri-space-800) var(--tri-space-1500);

  ${mq.mediumMaxWidth({ margin: 'var(--tri-space-500)', paddingBottom: 'var(--tri-space-1500)' })}
`

PageViewWrapper.defaultProps = {
  canStartNewApplication: false,
  dsaVersion: '',
}

PageViewWrapper.propTypes = {
  bdcOrgId: string,
  canStartNewApplication: bool,
  children: node,
  dsaVersion: string,
  inviteCollaborators: bool,
  pageName: string.isRequired,
}
