import { useQuery } from '@apollo/client'
import Form from '@divvy-web/skylab.form'
import Spinner from '@divvy-web/skylab.spinner'
import React, { useState, useEffect } from 'react'
import { Route, Routes, useParams, useSearchParams } from 'react-router-dom'
import { useCanary } from '@bill/canary.react'
import useApplicantTokenOnMount from '../../hooks/useApplicantTokenOnMount'
import { initialValues } from '../../pages/FormPages/initialValues'
import { regularPages } from '../../pages/FormPages/pages'
import GetCreditApplication from '../../pages/gql/GetCreditApplication.gql'
import NotFound from '../../pages/NotFound'
import { MAJOR_ACCOUNT_CHANGE, MIGRATION } from '../../resources/constants.js'
import { isRequestingCorrection } from '../../utils/dataUtils'
import { RawCreditAppContext } from '../../utils/RawCreditAppContext'
import ErrorPage from '../ErrorPage'
import FormPageWrapper from '../FormPageWrapper/FormPageWrapper'
import { getMaybeNeedsNewPassword, unsetMaybeNeedsNewPassword, useAuth } from '../../auth'
import CreatePasswordContainer from '../CreatePasswordContainer/CreatePasswordContainer'
import { isAccessToken } from '../../auth/jwt/jwt.js'
import { getOriginalAccessToken } from '../../auth/tokenUtils.js'
import getValidations from './getValidations'

const FormWrapper = () => {
  const params = useParams()
  const useNewAuth = useCanary('useNewAuth')
  const { email } = useAuth()
  const [searchParams] = useSearchParams()
  const appId = params.appId
  const [isSaving, setIsSaving] = useState(false)
  const [shouldShowIncompleteBanner, setShouldShowIncompleteBanner] = useState(false)
  const [clickedSaveAndExit, setClickedSaveAndExit] = useState(false)
  const [showCreatePasswordModal, setShowCreatePasswordModal] = useState(useNewAuth && !!getMaybeNeedsNewPassword())

  useApplicantTokenOnMount()

  const { data, error, loading, refetch } = useQuery(GetCreditApplication, {
    fetchPolicy: 'network-only',
    variables: { creditApplicationId: appId },
  })

  if (loading) return <Spinner fullPage />
  if (error) return <ErrorPage />

  const handleCreatePasswordSubmit = () => {
    unsetMaybeNeedsNewPassword()
    setShowCreatePasswordModal(false)
  }

  const creditApp = data?.creditApplication
  const bdcOrgId = data?.creditApplication?.bdcOrgId
  const isMigration = creditApp?.recordType === MIGRATION
  const isMajorAccountChange = creditApp?.recordType === MAJOR_ACCOUNT_CHANGE
  const requestingCorrection = creditApp && isRequestingCorrection(creditApp)
  const formInitialValues = initialValues(creditApp, 'creditApplication')
  const pages = regularPages(isMigration, requestingCorrection, isMajorAccountChange)
  const formPages = pages.map((page) => {
    const { component, description, hidePrevious, index, mutationKey, name, route, title } = page

    return (
      <Route
        key={'app-form-page-' + index}
        element={
          <FormPageWrapper
            bdcOrgId={bdcOrgId}
            clickedSaveAndExit={clickedSaveAndExit}
            hidePrevious={hidePrevious}
            isMigration={isMigration}
            mutationKey={mutationKey}
            pageCount={pages.length}
            pageIndex={index}
            pageName={name}
            pages={pages}
            pageSubTitle={description}
            pageTitle={title}
            refetchFormValues={refetch}
            setClickedSaveAndExit={setClickedSaveAndExit}
            setIsSaving={setIsSaving}
            setShouldShowIncompleteBanner={setShouldShowIncompleteBanner}
            shouldShowIncompleteBanner={shouldShowIncompleteBanner}
          >
            {component({
              alwaysShowError: shouldShowIncompleteBanner && !clickedSaveAndExit,
              isMigration: isMigration,
              isSaving: isSaving,
              serverBusinessValues: { admin: creditApp?.admin, ...creditApp?.businessInfo },
            })}
          </FormPageWrapper>
        }
        path={route}
      />
    )
  })

  return (
    <section>
      {isAccessToken(getOriginalAccessToken()) && (
        <CreatePasswordContainer
          isShowing={showCreatePasswordModal}
          usernameEmail={email}
          onSubmit={handleCreatePasswordSubmit}
        />
      )}
      <div className='form-container'>
        <Form
          initialValues={formInitialValues}
          validation={(formValues) =>
            getValidations({
              formValues,
              owner: searchParams.get('owner'),
              pageName: params['*'],
              requestedCorrections: requestingCorrection && creditApp?.sectionsToCorrect,
            })
          }
        >
          <RawCreditAppContext.Provider value={{ ...creditApp }}>
            <Routes>
              {formPages}
              <Route
                element={<NotFound />}
                path='/*'
              />
            </Routes>
          </RawCreditAppContext.Provider>
        </Form>
      </div>
    </section>
  )
}

export default FormWrapper
