import React from 'react'
import useNameFormatter from '@divvy-web/hooks.usenameformatter'
import { curry } from '@divvy-web/utils'
import { css } from '@emotion/core'
import { DropdownInput } from '../../components'
import FormElementWrapper from '../../components/FormInputs/FormElementWrapper'
import IndustryAutoComplete from '../../components/IndustryAutoComplete/IndustryAutoComplete'
import NaturalPersonInfo from './NaturalPersonInfo'
import { accountingSoftwareOptions, entityTypeOptions } from './signUpConstants'

interface SignUpBusinessFieldsProps {
  initialMacBusinessValues: {
    entity: string
    industry: string
    naicsCode: string
  }
  isMac: boolean
  isPersonal: boolean
  isReview: boolean
  readOnly: boolean
}

const SignUpBusinessFields = ({
  initialMacBusinessValues,
  isMac,
  isPersonal,
  isReview,
  readOnly,
}: SignUpBusinessFieldsProps) => {
  const [getClassName, makeTestId] = useNameFormatter('SignUp-Form')

  const { entity, industry, naicsCode } = initialMacBusinessValues || {}

  return (
    <>
      <div
        className='field-row-entity fs-unmask'
        css={signUpStyles({ isPersonal })}
      >
        <div
          className='field-container'
          id='entity'
        >
          {isPersonal && <NaturalPersonInfo />}
          <FormElementWrapper>
            <DropdownInput
              isSearchable
              alwaysShowError={readOnly}
              caption={undefined}
              className={getClassName('entity-type')}
              dataTestId={makeTestId('entityType')}
              disabled={isMac && !!entity}
              errorCaption={undefined}
              hasError={undefined}
              items={entityTypeOptions}
              label='Entity type'
              name='entityType'
              placeholder='Entity type'
              readOnly={readOnly}
              onSelectionChange={undefined}
            />
          </FormElementWrapper>
        </div>
        <div className='field-container'>
          <IndustryAutoComplete
            disabled={isMac && !!industry && !!naicsCode}
            isReview={isReview}
            readOnly={readOnly}
          />
        </div>
      </div>
      {!isReview && (
        <div
          className='field-row-entity fs-unmask'
          css={signUpStyles({ isPersonal })}
        >
          <div className='field-container'>
            <FormElementWrapper>
              <DropdownInput
                isSearchable
                alwaysShowError={readOnly}
                caption={undefined}
                className={getClassName('accounting-software')}
                dataTestId={makeTestId('accounting-software')}
                disabled={undefined}
                errorCaption={undefined}
                hasError={undefined}
                items={accountingSoftwareOptions}
                label='Accounting software'
                name='accountingSoftware'
                placeholder='Accounting software'
                readOnly={isReview}
                onSelectionChange={undefined}
              />
            </FormElementWrapper>
          </div>
        </div>
      )}
    </>
  )
}

const signUpStyles = curry(
  ({ isPersonal }, theme) => css`
    display: flex;
    gap: var(--tri-space-800);
    ${theme.mq.xSmallMaxWidth({ flexDirection: 'column', gap: 'var(--tri-space-200)' })}

    .field-container {
      display: ${isPersonal ? 'none' : 'block'};
    }

    #entity {
      display: ${isPersonal ? 'flex' : 'block'};
      flex-direction: column;
      gap: var(--tri-space-600);
    }
  `,
)

export default SignUpBusinessFields
