import React, { useRef, useState } from 'react'
import useDidMount from '@divvy-web/hooks.usedidmount'
import { FormattedMessage } from '@divvy-web/i18n'
import Form, { FormTextInput } from '@divvy-web/skylab.form'
import TextLink from '@divvy-web/skylab.textlink'
import { css } from '@emotion/core'
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom'
import { useCanary } from '@bill/canary.react'
import { setApplicantDataFromUPFromStorage } from '../../auth/localStorageUtils'
import { setMaybeNeedsNewPassword, useAuth } from '../../auth'
import { FormButtons } from '../../components'
import FormElementWrapper from '../../components/FormInputs/FormElementWrapper'
import InformativeBanner from '../../components/InformativeBanner'
import MiscPageWrapper from '../../components/MiscPageWrapper/MiscPageWrapper'
import PageViewWrapper from '../../components/PageViewWrapper/PageViewWrapper'
import RecaptchaDisclaimer from '../../components/RecaptchaDisclaimer'
import useGetBillProduct from '../../hooks/useGetBillProduct'
import { PATHNAME_CONSTS, URL } from '../../resources/constants'
import { removeDealSourceIdFromStorage } from '../../utils/dealSourceUtils'
import { logInfo } from '../../utils/loggerUtils'
import { signinValidation } from './signInForm'

const SignIn = () => {
  const migrationPath = useRef(false)
  const navigate = useNavigate()
  const useNewAuth = useCanary('useNewAuth')
  const { getAuthenticationMethod, requestAuthCode, setEmail, setToken } = useAuth()
  const { pathname, search, state } = useLocation()
  const [hasError, setHasError] = useState(false)
  const [searchParams] = useSearchParams()
  const [loading, setLoading] = useState(false)
  const [isVisible, setIsVisible] = useState(true)
  const { company, product } = useGetBillProduct()
  /* This can be self / forced or na
   * if self we want to show the logged out successfully msg
   * if forced we want to show logged out banner with instructions
   * if na the user has landed on the page for the first time and
   * we do not want to show either
   */
  const logoutType = state?.logout ?? 'na'
  const [unAuthReason, setUnAuthReason] = useState(logoutType)
  const isMigration = migrationPath.current
  const { BILL_PRIVACY_NOTICE, BILL_TERMS_AND_CONDITIONS } = URL
  const resetError = () => hasError && setHasError(false)

  const executeGetAuthMethod = (email) => {
    getAuthenticationMethod(email).then((authMethod) => {
      if (authMethod === 'PASSWORD') {
        navigate(PATHNAME_CONSTS.VERIFY_PASSWORD_PATH, { state: { email } })
      } else {
        useNewAuth && setMaybeNeedsNewPassword()
        executeRequestAuthCode(email)
      }
    })
  }

  const executeRequestAuthCode = (email) =>
    requestAuthCode(email)
      .then(() => {
        logInfo({
          attributes: {
            action: 'requestAuthCode',
            result: 'Sending user to verify access code page',
          },
          eventName: 'SignIn',
        })
        navigate(
          {
            pathname: PATHNAME_CONSTS.VERIFY_EMAIL_PATH,
            search: search,
          },
          { state },
        )
      })
      .catch(() => {
        setHasError(true)
        setLoading(false)
      })

  const handleSubmit = (_, values) => {
    setLoading(true)
    const { signInEmail } = values

    logInfo({
      attributes: {
        action: 'handleSubmit',
        result: 'Sign in button clicked',
      },
      eventName: 'SignIn',
    })

    useNewAuth ? executeGetAuthMethod(signInEmail) : executeRequestAuthCode(signInEmail)
    setEmail(signInEmail)
  }

  const clearHistoryState = () => {
    // If the user refreshes the browser we do not want to show logout messages
    // a second time.  This will clear the history state without a render.

    navigate(
      {
        pathname: migrationPath.current ? PATHNAME_CONSTS.MIGRATION_PATH : PATHNAME_CONSTS.AUTH_PATH,
        search: search ?? '',
      },
      {
        replace: true,
        state: { logout: 'na' },
      },
    )
  }

  useDidMount(() => {
    migrationPath.current = pathname === PATHNAME_CONSTS.MIGRATION_PATH
    setUnAuthReason(logoutType)
    clearHistoryState()
    removeDealSourceIdFromStorage()

    logInfo({
      attributes: {
        result: 'Sign In page loaded',
      },
      eventName: 'PageLoad',
    })

    if (search) {
      setApplicantDataFromUPFromStorage(search)
    }

    if (searchParams.get('referrer') === 'mobile-doc-upload' || searchParams.get('authToken')) {
      setToken(searchParams.get('authToken'))
      navigate({ pathname: PATHNAME_CONSTS.MOBILE_DOCUMENT_UPLOAD_PATH })
    }

    if (searchParams.get('email')) {
      executeRequestAuthCode(searchParams.get('email'))
    }
  })

  const showLogoutMessage = () => {
    if (unAuthReason === 'forced') {
      logInfo({
        attributes: {
          action: 'showLogoutMessage',
          message: 'forced logout as session timed out',
          result: 'Sign In page loaded',
        },
        eventName: 'PageLoad',
      })

      return (
        <InformativeBanner
          isDismissable
          isVisible={isVisible}
          message={
            <FormattedMessage
              defaultMessage='Sign in to continue your application'
              id='sputnik.SignIn__7L6VK1'
            />
          }
          title={
            <FormattedMessage
              defaultMessage='Your session has timed out.'
              id='sputnik.SignIn__fSlbLN'
            />
          }
          onClose={() => setIsVisible(false)}
        />
      )
    }
    return null
  }

  return (
    <PageViewWrapper pageName='SignIn'>
      <MiscPageWrapper
        headingStyles={headingStyles}
        pageName='signin'
        pageStyles={signinStyles}
        pageSubTitle={
          <FormattedMessage
            defaultMessage='Enter your email below to start your application, pick up where you left off, or check the status of a submitted application.'
            id='sputnik.SignIn__EFJVRU'
          />
        }
        pageTitle={
          <FormattedMessage
            defaultMessage='Welcome to the {product} application'
            id='sputnik.SignIn__SShePs'
            values={{ product }}
          />
        }
      >
        {showLogoutMessage()}
        <div
          className='sign-in-form'
          data-testid='auth-page'
        >
          <Form
            dataTestId='sign-in-form'
            validation={signinValidation}
            onChange={resetError}
            onSubmit={handleSubmit}
          >
            <div className='field-container fs-unmask'>
              <FormElementWrapper
                errorCaption={
                  <FormattedMessage
                    defaultMessage='An error has occured. Please contact a sales representative.'
                    id='sputnik.SignIn__v/JjHw'
                  />
                }
                hasError={hasError}
              >
                <FormTextInput
                  // @ts-expect-error need classname for styles
                  className='signIn-email'
                  dataTestId='SignIn-Field'
                  label={
                    <FormattedMessage
                      defaultMessage='Enter your business email'
                      id='sputnik.SignIn__7/X4+G'
                    />
                  }
                  name='signInEmail'
                  placeholder={
                    <FormattedMessage
                      defaultMessage='Enter your business email'
                      id='sputnik.SignIn__7/X4+G'
                    />
                  }
                  type='email'
                />
              </FormElementWrapper>
            </div>
            <FormButtons
              hideExit
              hidePrevious
              disableNext={loading || hasError}
              // @ts-expect-error need id for styles
              id='signin-submit'
              nextButtonType='submit'
              pageName='SignIn'
              showNextSpinner={loading && !hasError}
            />
          </Form>
          <div className='terms-and-conditions fs-unmask'>
            <FormattedMessage
              defaultMessage="By continuing, you agree to {product}’s <termsLink>Terms and Conditions</termsLink> and {company}'s <privacyLink>Privacy Notice</privacyLink>."
              id='sputnik.SignIn__/HqPJQ'
              values={{
                company,
                privacyLink: (textValue) => (
                  <TextLink
                    href={BILL_PRIVACY_NOTICE}
                    target='_blank'
                  >
                    {textValue}
                  </TextLink>
                ),
                product,
                termsLink: (textValue) => (
                  <TextLink
                    href={BILL_TERMS_AND_CONDITIONS}
                    target='_blank'
                  >
                    {textValue}
                  </TextLink>
                ),
              }}
            />
          </div>
          {!isMigration && (
            <div className='already-use fs-unmask'>
              <FormattedMessage
                defaultMessage='Already use {product}? <divvyAppLink>Click here to sign in</divvyAppLink>'
                id='sputnik.SignIn__4o7PXu'
                values={{
                  divvyAppLink: (textValue) => (
                    <a
                      className='click-here'
                      href='https://app.divvy.co'
                      rel='noopener noreferrer'
                      target='_blank'
                    >
                      {textValue}
                    </a>
                  ),
                  product,
                }}
              />
            </div>
          )}
          <RecaptchaDisclaimer
            // @ts-expect-error need id for styles
            id='disclaimer-recap'
          />
        </div>
      </MiscPageWrapper>
    </PageViewWrapper>
  )
}

export default SignIn

const headingStyles = ({ mq, type }) => css`
  margin-left: var(--tri-space-1500);
  ${mq.xSmallMaxWidth({ margin: 0, padding: 0 })}

  .signin-page-heading-title {
    ${type.triFontDesktopTitleLargeEmphasis};
    width: 65%;
    margin-bottom: var(--tri-space-250);
    ${mq.xSmallMaxWidth(type.triFontDesktopTitleSmallEmphasis)}
    ${mq.xSmallMaxWidth({
      backgroundColor: 'var(--tri-color-fill-accent-primary)',
      margin: '0',
      padding: 'var(--tri-space-450) var(--tri-space-300) var(--tri-space-400) var(--tri-space-300)',
      width: '100%',
    })}
  }

  .signin-page-heading-subtitle {
    ${mq.largeMaxWidth({ width: '50%' })}
    ${mq.xLargeMinWidth({ width: '45%' })}
    ${mq.xxLargeMinWidth({ width: '35%' })}
    width: 46%;
    margin-bottom: var(--tri-space-550);
    ${type.triFontDesktopBodyMedium};
    ${mq.xSmallMaxWidth({
      backgroundColor: 'var(--tri-color-fill-primary)',
      color: 'var(--tri-color-text-primary)',
      marginBottom: '0',
      padding: 'var(--tri-space-350) var(--tri-space-400) var(--tri-space-100) var(--tri-space-300)',
      width: '100%',
    })}
  }
`

const signinStyles = ({ mq, type }) => css`
  margin-top: 20vh;

  a {
    color: var(--tri-color-fill-info-inverse);
  }

  ${mq.xSmallMaxWidth({ margin: 0 })}

  .sign-in-form {
    margin-left: var(--tri-space-1500);
    ${mq.xSmallMaxWidth({
      marginLeft: 0,
      marginTop: 'var(--tri-space-600)',
      padding: '0 var(--tri-space-400) var(--tri-space-300) var(--tri-space-300)',
    })}
  }

  [class*='signin-submit'] {
    margin-top: var(--tri-space-500);
    margin-bottom: var(--tri-space-900);
  }

  .field-container {
    width: 50%;
    max-width: 560px;
    ${mq.xSmallMaxWidth({ maxWidth: 'none', width: '100%' })}
  }

  .already-use {
    ${type.triFontDesktopBodySmall};
    margin-top: var(--tri-space-500);
    ${mq.xSmallMaxWidth(type.triFontDesktopCaptionSmall)}
    ${mq.xSmallMaxWidth({ marginTop: 'var(--tri-space-500)' })}
  }

  .terms-and-conditions {
    margin-top: var(--tri-space-400);
    ${type.triFontDesktopBodySmall}
    a {
      text-decoration: none;
    }
  }

  [class*='FormButtons-container'] {
    margin-top: var(--tri-space-450);
    padding: 0;
    ${mq.smallMinWidth({ paddingLeft: 0, position: 'relative' })}
  }

  [class*='FormButtons-container'] > .button-group-container {
    ${mq.smallMinWidth({ width: 'auto' })}
  }

  [class*='recaptcha-container'] {
    margin-top: var(--tri-space-100);
    width: 288px;
    ${type.triFontDesktopCaptionSmall};
    ${mq.xSmallMaxWidth({
      marginTop: 'var(--tri-space-300)',
      paddingBottom: 'var(--tri-space-1300)',
      width: 'auto',
    })}
  }

  .click-here {
    text-decoration: none;
  }
`
