import React, { useEffect, useState } from 'react'
import { oneOf } from 'prop-types'
import { useParams } from 'react-router-dom'
import { useMakeTestId } from '@divvy-web/hooks.usenameformatter'
import { useCanary } from '@bill/canary.react'
import { FormattedMessage } from '@divvy-web/i18n'
import BasicButton, { BASIC_BUTTON_TYPE_FLAT } from '@divvy-web/skylab.basicbutton'
import Modal from '@divvy-web/skylab.modal'
import { TOAST_TYPE_BASIC, useToast } from '@divvy-web/skylab.toast'
import { getTokenFromStorage, useAuth } from '../../auth'
import { getExpirationFromToken } from '../../auth/jwt'
import { useGetAutoSaveMutation } from '../../hooks/useGetAutoSaveMutation'
import { PageMutationKey } from '../../resources/constants'
import { logError, logInfo } from '../../utils/loggerUtils'
import { epochMinutesFromNow } from '../utils'
import { handleQATimeoutValues } from './qaUtils'

const modalIconPath = 'https://app.divvy.co/assets/illustrations/alarm-clock-yellow'
const mainModalIconSrc = `${modalIconPath}@1x.png`
const modalIconSrcSet = `${modalIconPath}@3x.png, ${modalIconPath}@2x.png, ${modalIconPath}@1x.png`
const headerImage = (
  <img
    alt='post-it'
    src={mainModalIconSrc}
    srcSet={modalIconSrcSet}
  />
)

// time to start countdown in seconds
const startCountdownTime = 60 * 2
// time prior to expiration in ms
const timeoutPriorToExpiration = 1000 * 60 * 2

/*
 * This component will display a modal
 * to warn the user that their session is expiring
 *
 * The modal pops up in defaultTimeoutDelayTime (ms)
 *
 * The modal counts down every 1000ms
 * for a duration of startCountdownTime
 */
const TimeoutWarningModal = ({ pageName }) => {
  const isDashboard = pageName === 'dashboard'
  const reduceAccessTokenTimeoutFlagValue = useCanary('reduceAccessTokenTimeout')
  const { hasReduceAccessTokenTimeout, qaStartCountdownTime, qaTimeoutDelayTime } = handleQATimeoutValues(
    reduceAccessTokenTimeoutFlagValue,
  )
  const [countdownTimer, setCountdownTimer] = useState(startCountdownTime)
  const [showModal, setShowModal] = useState(false)
  const makeTestId = useMakeTestId('TimeoutWarningModal')
  const showToast = useToast()

  const { appId } = useParams()
  const [autoSaveMutation] = useGetAutoSaveMutation({ appId, pageName })

  const { logout, refreshAuthToken } = useAuth()
  const token = getTokenFromStorage()

  const expirationDateInSeconds = token ? getExpirationFromToken(token) : epochMinutesFromNow(15)
  const expirationDateInMs = expirationDateInSeconds * 1000
  const timeToShowWarningInMs = expirationDateInMs - timeoutPriorToExpiration
  const currentDateInMs = Date.now()
  const defaultTimeoutDelayTime = timeToShowWarningInMs - currentDateInMs
  const timeoutDelayTime = hasReduceAccessTokenTimeout ? qaTimeoutDelayTime : defaultTimeoutDelayTime
  const tokenIsExpired = currentDateInMs > expirationDateInMs
  const autoHideDelay = (qaStartCountdownTime || 5) * 1000

  const closeModal = () => setShowModal(false)
  const openModal = () => setShowModal(true)
  const resetCountdownTimer = () =>
    setCountdownTimer(hasReduceAccessTokenTimeout ? qaStartCountdownTime : startCountdownTime)

  // trigger modal 2 minutes prior to expiration
  const timeoutId =
    !showModal && timeoutDelayTime > 0
      ? setTimeout(() => {
          hasReduceAccessTokenTimeout && resetCountdownTimer()
          openModal()
          clearTimeout(timeoutId)

          autoSaveMutation()
            .then(() => {
              logInfo({
                attributes: {
                  action: 'Auto save',
                  message: isDashboard
                    ? 'Auto save successful on dashboard'
                    : `Auto save successful on credit app id: ${appId}`,
                },
                eventName: 'Timeout',
              })
            })
            .catch(() => {
              logError({
                attributes: {
                  action: 'Auto save',
                  message: isDashboard
                    ? 'Auto save failed on dashboard'
                    : `Auto save failed on credit app id: ${appId}`,
                },
                eventName: 'Timeout',
              })
            })

          logInfo({
            attributes: {
              action: 'Session expiring',
              message: isDashboard ? 'Session expiring on dashboard' : `Session expiring on credit app id: ${appId}`,
              result: 'Timeout warning modal displayed',
            },
            eventName: 'Timeout',
          })
        }, timeoutDelayTime)
      : null

  // start countdown if modal is shown
  const intervalId = showModal
    ? setInterval(() => {
        if (countdownTimer > 0) {
          clearInterval(intervalId)
          setCountdownTimer((time) => time - 1)
        }
      }, 1000)
    : null

  useEffect(() => {
    // clear interval on unmount
    return () => {
      if (intervalId) {
        clearInterval(intervalId)
      }

      if (timeoutId) {
        clearTimeout(timeoutId)
      }
    }
  })

  if (!showModal) return null

  const logoutApplication = () => {
    clearInterval(intervalId)
    logout()
  }

  const handleLogoutClicked = () => {
    logInfo({
      attributes: {
        action: 'handleLogoutClicked',
        message: isDashboard ? 'Logout clicked on dashboard' : `Logout clicked on credit app id: ${appId}`,
        result: 'Logout clicked',
      },
      eventName: 'Timeout',
    })

    logoutApplication()

    showToast(
      TOAST_TYPE_BASIC,
      <FormattedMessage
        defaultMessage='Log out successful!'
        id='sputnik.TimeoutWarningModal__VckeyC'
      />,
      {
        autoHideDelay,
        dataTestId: 'logout-success-toast',
      },
    )
  }

  const resumeSession = () => {
    resetCountdownTimer()
    closeModal()
  }

  const handleResumeClicked = () => {
    logInfo({
      attributes: {
        action: 'handleResumeClicked',
        message: isDashboard ? 'Resume clicked on dashboard' : `Resume clicked on credit app id: ${appId}`,
        result: 'Resume timeout clicked',
      },
      eventName: 'Timeout',
    })

    clearInterval(intervalId)
    refreshAuthToken()
      .then(() => {
        resumeSession()

        logInfo({
          attributes: {
            action: 'handleResumeClicked',
            message: `Auth token refreshed on credit app id: ${appId}`,
            result: 'Auth token refreshed',
          },
          eventName: 'Timeout',
        })
      })
      .catch(() => {
        resumeSession()

        logError({
          attributes: {
            action: 'handleResumeClicked',
            message: isDashboard
              ? 'Error refreshing auth token on dashboard'
              : `Error refreshing auth token on credit app id: ${appId}`,
            result: 'Error refreshing auth token',
          },
          eventName: 'Timeout',
        })
      })
  }

  // Kill timer and automatically log customer out at 0 or if token is expired
  if (countdownTimer === 0 || tokenIsExpired) {
    clearInterval(intervalId)
    logoutApplication()

    showToast(
      TOAST_TYPE_BASIC,
      <FormattedMessage
        defaultMessage='You’ve been logged out due to inactivity.'
        id='sputnik.TimeoutWarningModal__UhBQi7'
      />,
      {
        autoHideDelay,
        dataTestId: 'logout-inactivity-toast',
      },
    )

    logInfo({
      attributes: {
        action: 'Session expired',
        message: `Session expired on credit app id: ${appId}`,
        result: 'Logged out',
      },
      eventName: 'Timeout',
    })
  }

  const modalActionButtons = [
    <BasicButton
      key='logout'
      color='neutral'
      dataTestId={makeTestId('logout')}
      type={BASIC_BUTTON_TYPE_FLAT}
      onClick={handleLogoutClicked}
    >
      <FormattedMessage
        defaultMessage='Log out'
        id='sputnik.TimeoutWarningModal__PlBReU'
      />
    </BasicButton>,
    <BasicButton
      key='resume'
      dataTestId={makeTestId('resume')}
      onClick={handleResumeClicked}
    >
      <FormattedMessage
        defaultMessage='Resume'
        id='sputnik.TimeoutWarningModal__3y9DGg'
      />
    </BasicButton>,
  ]

  return (
    <Modal
      actions={modalActionButtons}
      dataTestId={makeTestId('modal')}
      headerColor='info'
      headerImage={headerImage}
      isShowing={showModal}
      title='Session timeout'
    >
      <div>
        <FormattedMessage
          defaultMessage='You’ve been inactive for a while. For your security, we’ll automatically log you out in approximately {countdownTime} <b>seconds</b>'
          id='sputnik.TimeoutWarningModal__co2hNp'
          values={{
            b: (text) => <b>{text}</b>,
            countdownTime: <b>{countdownTimer}</b>,
          }}
        />
      </div>
    </Modal>
  )
}

TimeoutWarningModal.propTypes = {
  pageName: oneOf([...Object.values(PageMutationKey), 'dashboard']),
}

export default TimeoutWarningModal
