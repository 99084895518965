import React from 'react'
import { FormattedMessage } from '@divvy-web/i18n'
import { dissoc, has, keys, length, mapObjIndexed, pipe, reduce } from '@divvy-web/utils'
import { currencyToFixedString, integerToDecimal } from '@divvy-web/utils.currency'
import parsePhoneNumber from 'libphonenumber-js'
import { getAccountingSoftwareItems } from '../pages/SignUp/signUpConstants'
import { MANUAL_ID } from '../pages/FinanceInfo/financeInfoConstants'
import RequestSignature from '../pages/gql/mutations/RequestSignature.gql'
import SubmitCreditApplication from '../pages/gql/mutations/SubmitCreditApplication.gql'
import SubmitMajorAccountChange from '../pages/gql/mutations/SubmitMajorAccountChange.gql'
import SubmitDataVerification from '../pages/gql/mutations/SubmitDataVerification.gql'
import UpdateBeneficialOwners from '../pages/gql/mutations/UpdateBeneficialOwners.gql'
import UpdateBusinessInfo from '../pages/gql/mutations/UpdateBusinessInfo.gql'
import UpdateFinanceInfo from '../pages/gql/mutations/UpdateFinanceInfo.gql'
import UpdateSigner from '../pages/gql/mutations/UpdateSigner.gql'
import UpdateSignUp from '../pages/gql/mutations/UpdateSignUp.gql'
import { dateStringToEpoch } from '../components/utils'
import { PageMutationKey } from '../resources/constants'
import { getClientTimezone } from './timezoneUtil'
import { passportToggled, removeNonNumericCharacters } from './dataUtils'
import { logError, logWarning } from './loggerUtils'

const gqlMapping = {
  [PageMutationKey.PAGE_BUSINESS_INFO]: UpdateBusinessInfo,
  [PageMutationKey.PAGE_COMPANY_OWNERS]: UpdateBeneficialOwners,
  [PageMutationKey.PAGE_AUTHORIZED_SIGNER]: UpdateSigner,
  [PageMutationKey.PAGE_FINANCE_INFO]: UpdateFinanceInfo,
  [PageMutationKey.PAGE_REVIEW_AND_SIGN]: SubmitCreditApplication,
  [PageMutationKey.PAGE_REVIEW_AND_VALIDATE]: SubmitDataVerification,
  [PageMutationKey.PAGE_SIGNUP]: UpdateSignUp,
}

const getFullMonthStringFromDate = (date) => (date.getMonth() + 1).toString().padStart(2, 0)
const getFullDayOfMonthStringFromDate = (date) => date.getDate().toString().padStart(2, 0)

export const epochToDate = (epoch) => {
  if (!epoch) return ''

  const date = new Date(epoch)
  date.setHours(0)
  date.setMinutes(0)
  date.setSeconds(0)
  date.setMilliseconds(0)

  const year = date.getFullYear()

  if (year > 1700) {
    return `${year}-${getFullMonthStringFromDate(date)}-${getFullDayOfMonthStringFromDate(date)}`
  }

  return ''
}

export const castToCurrency = (currencyValue) => {
  if (typeof currencyValue !== 'number') return currencyValue
  const integerToFixedString = integerToDecimal(2)
  const formatFixedString = currencyToFixedString(2)
  const formattedAmount = formatFixedString(integerToFixedString(currencyValue))
  return formattedAmount
}
const getKeysLength = pipe(keys, length)
const removeUnsavedBusinessInfoKeys = pipe(dissoc('mailingPhysicalAddressesEqual'))
const removeUnsavedFinanceInfoKeys = pipe(
  dissoc('hasAttemptedBankLink'),
  dissoc('id'),
  dissoc('source'),
  dissoc('accountNumberLastFour'),
  dissoc('accountNumberDisplay'),
  dissoc('selectedBankInfo'),
  dissoc('externalConnectionGuid'),
  dissoc('logoUrl'),
)
const removeUnsavedPersonKeys = pipe(
  dissoc('isAuthorizedSigner'),
  dissoc('ssnLastFour'),
  dissoc('passportNumberDisplay'),
  dissoc('passportNumberLength'),
  dissoc('passportNumberLastFour'),
)

const unflattenAddress = (owner) => {
  const {
    addressCity,
    addressCountryCode,
    addressPostalCode,
    addressState,
    addressStreet,
    addressStreetAdditional,
    ...rest
  } = owner

  return {
    ...rest,
    address: {
      city: addressCity,
      countryCode: addressCountryCode,
      postalCode: addressPostalCode,
      state: addressState,
      street: addressStreet,
      streetAdditional: addressStreetAdditional,
    },
  }
}

export const replaceDropdownAsString = (fieldValue = '') => {
  const hasSubKeys = getKeysLength(fieldValue) > 0
  const isDropdown = has('label', fieldValue)
  if (hasSubKeys && !isDropdown) {
    const mappedValue = mapObjIndexed(replaceDropdownAsString, fieldValue)
    return mappedValue
  } else {
    return fieldValue?.value ?? fieldValue
  }
}

const checkIfValueIsAnObject = (value) => {
  if (
    !!value ||
    value instanceof Array ||
    value instanceof Function ||
    value instanceof Set ||
    value instanceof Map ||
    value instanceof Date ||
    typeof value !== 'object'
  ) {
    return false
  }

  try {
    return !!Object.keys(value).length
  } catch {
    return false
  }
}

export const removeUndefinedValuesFromObject = (formData) => {
  const formDataKeys = Object.keys(formData)
  return formDataKeys.reduce((accum, key) => {
    const fieldValue = formData?.[key]

    if (fieldValue === undefined) return accum

    const isFieldValueAnObject = checkIfValueIsAnObject(fieldValue)
    return {
      ...accum,
      [key]: isFieldValueAnObject ? removeUndefinedValuesFromObject(fieldValue) : fieldValue,
    }
  }, {})
}

export const removeKeyFromObj = (keyName, obj) => {
  let scrubbedObj = { ...obj } // Create a copy to avoid modifying the original object

  for (const key in scrubbedObj) {
    if (key === keyName) {
      delete scrubbedObj[keyName]
    } else if (typeof scrubbedObj[key] === 'object' && scrubbedObj[key] !== null) {
      // Check if it's a plain object and not null
      if (!scrubbedObj[key].hasOwnProperty('__typename')) {
        // Only recurse if it's not a GraphQL object
        scrubbedObj[key] = removeKeyFromObj(keyName, scrubbedObj[key])
      }
    }
  }

  return scrubbedObj
}

const dobPluck = (obj) => {
  const { dob, ...rest } = obj
  const date = epochToDate(dob)
  const dobFields = date ? { dob: date } : {}
  return { ...rest, ...dobFields }
}

const castBeneficialOwnersDate = (owner) => {
  if (owner?.passportExpiration) {
    const passportExpirationEpoch = dateStringToEpoch(owner?.passportExpiration)
    return {
      ...owner,
      passportExpiration: epochToDate(passportExpirationEpoch),
    }
  }

  return owner
}

const getPassportField = (person) => {
  const { passportNumber } = person
  if (!passportNumber) {
    return {}
  } else if (!passportToggled(person)) {
    return { passportNumber: '' }
  } else {
    return passportNumber && !passportNumber?.includes('*') ? { passportNumber } : {}
  }
}

const getSsnField = (person) => {
  const { ssn } = person
  if (!person?._passportToggle) {
    return ssn && !ssn?.includes('*') ? { ssn: removeNonNumericCharacters(ssn) } : {}
  }

  return { ssn: '' }
}

const prepBeneficialOwners = (accum, owner) => {
  let replacedOwner = {}
  if (owner.isAuthorizedSigner) {
    replacedOwner.id = owner.id
  } else {
    replacedOwner = pipe(
      removeUnsavedPersonKeys,
      mapObjIndexed(replaceDropdownAsString),
      unflattenAddress,
      castBeneficialOwnersDate,
    )(owner)

    const { _passportToggle: _, passportNumber, phoneNumber, ssn, ...rest } = replacedOwner

    const passportNumberField = getPassportField(owner)
    const ssnField = getSsnField(owner)
    const formattedPhoneNumber =
      owner?.phoneNumber === '+1' ? null : parsePhoneNumber(owner?.phoneNumber || '', 'US')?.format('E.164') ?? ''

    replacedOwner = {
      ...rest,
      ...passportNumberField,
      ...ssnField,
      phoneNumber: formattedPhoneNumber,
    }
    replacedOwner = removeUndefinedValuesFromObject(replacedOwner)
  }

  const { dobDisplay, isAuthorizedSigner, ssnDisplay, ...rest } = replacedOwner

  accum = [...accum, dobPluck(rest)]
  return accum
}

const formatSignup = (pageValues) => {
  let signupData = pageValues

  const businessInfo = pipe(removeUnsavedBusinessInfoKeys, mapObjIndexed(replaceDropdownAsString))(signupData)

  const { entityType, industry, naicsCode, accountingSoftware } = businessInfo

  let signupFormData = {
    businessInfo: {
      entityType,
      industry,
      naicsCode,
      accountingSoftware,
    },
  }

  return removeUndefinedValuesFromObject(signupFormData)
}

const formatBusinessInfo = (pageValues) => {
  let businessInfoData = pageValues

  const businessInfo = pipe(removeUnsavedBusinessInfoKeys, mapObjIndexed(replaceDropdownAsString))(businessInfoData)

  const mailingPhysicalAddressesEqual = !businessInfo?.validateMailAddressFormFields

  const {
    adminEmail,
    adminFirstName,
    adminId,
    adminLastName,
    adminPhoneNumber,
    accountingSoftware,
    adminPreferredFullName,
    formationDate,
    mailingAddressCity,
    mailingAddressCountryCode,
    mailingAddressPostalCode,
    mailingAddressState,
    mailingAddressStreet,
    mailingAddressStreetAdditional,
    ownership,
    physicalAddressCity,
    physicalAddressCountryCode,
    physicalAddressPostalCode,
    physicalAddressState,
    physicalAddressStreet,
    physicalAddressStreetAdditional,
    preferredAccountName,
    recordType,
    shouldShowTaxIdError: _,
    taxId,
    taxIdDisplay,
    validateMailAddressFormFields: ___,
    website,
    websiteFieldNotRequired: __,
    ...rest
  } = businessInfo

  const mailingAddress = {
    city: mailingPhysicalAddressesEqual ? physicalAddressCity : mailingAddressCity,
    countryCode: 'US',
    postalCode: mailingPhysicalAddressesEqual ? physicalAddressPostalCode : mailingAddressPostalCode,
    state: mailingPhysicalAddressesEqual ? physicalAddressState : mailingAddressState,
    street: mailingPhysicalAddressesEqual ? physicalAddressStreet : mailingAddressStreet,
    streetAdditional: mailingPhysicalAddressesEqual ? physicalAddressStreetAdditional : mailingAddressStreetAdditional,
  }

  const formationDateAsEpoch = dateStringToEpoch(formationDate)

  const taxIdField = taxId && !taxId?.includes('*') ? { taxId: removeNonNumericCharacters(taxId) } : {}

  const formattedAdminPhone =
    adminPhoneNumber === '+1' ? null : parsePhoneNumber(adminPhoneNumber || '', 'US')?.format('E.164') ?? ''

  let businessInfoFormData = {
    businessInfo: {
      ...rest,
      ...taxIdField,
      formedOn: epochToDate(formationDateAsEpoch),
      accountingSoftware: getAccountingSoftwareItems(accountingSoftware),
      mailingAddress: {
        ...mailingAddress,
      },
      ownership: ownership || null,
      physicalAddress: {
        city: physicalAddressCity,
        countryCode: 'US',
        postalCode: physicalAddressPostalCode,
        state: physicalAddressState,
        street: physicalAddressStreet,
        streetAdditional: physicalAddressStreetAdditional,
      },
      preferredAccountName: preferredAccountName ?? null,
      website: website ?? null,
    },
  }

  const adminData = {
    admin: {
      email: adminEmail || null,
      firstName: adminFirstName || null,
      id: adminId || null,
      lastName: adminLastName || null,
      phoneNumber: formattedAdminPhone || null,
      preferredFullName: adminPreferredFullName || null,
    },
  }

  return removeUndefinedValuesFromObject({ ...businessInfoFormData, ...adminData })
}

const nonNumRegex = /[^0-9]/g

const formatFinanceInfo = (pageValues) => {
  const financeInfo = removeUnsavedFinanceInfoKeys(pageValues)

  const {
    accountNumber,
    annualRevenue,
    billingCycle,
    desiredCredit,
    expectedSpend,
    hasOutsourcedAccountant,
    oaEmail,
    oaFirstName,
    oaId,
    oaLastName,
    selectedExternalId,
    ...rest
  } = financeInfo

  const accountNumberField =
    accountNumber && !accountNumber?.includes('*') ? { accountNumber: removeNonNumericCharacters(accountNumber) } : {}

  const selectedExternalIdField = selectedExternalId
    ? selectedExternalId === MANUAL_ID
      ? { selectedExternalId: null }
      : { selectedExternalId }
    : {}

  const hasOutsourcedAccountantBool = hasOutsourcedAccountant === 'yes'
  let outsourcedAccountantMutationValues = {}

  if (hasOutsourcedAccountantBool) {
    outsourcedAccountantMutationValues = {
      email: oaEmail || null,
      firstName: oaFirstName,
      id: oaId,
      lastName: oaLastName,
    }
  }

  const annualRevenueAsNumber = annualRevenue ? annualRevenue?.replace(nonNumRegex, '') : ''

  const desiredCreditAsNumber = desiredCredit ? desiredCredit?.replace(nonNumRegex, '') : ''
  const expectedSpendAsNumber = expectedSpend ? expectedSpend?.replace(nonNumRegex, '') : ''

  return {
    billingCycle,
    businessInfo: {
      annualRevenue: Number(annualRevenueAsNumber) ? Number(annualRevenueAsNumber * 100) : null,
    },
    financeInfo: {
      bankInfo: {
        ...accountNumberField,
        ...rest,
      },
      desiredCredit: Number(desiredCreditAsNumber) ? Number(desiredCreditAsNumber * 100) : null,
      expectedSpend: Number(expectedSpendAsNumber) ? Number(expectedSpendAsNumber * 100) : null,
      ...selectedExternalIdField,
    },
    hasOutsourcedAccountant: hasOutsourcedAccountantBool,
    outsourcedAccountant: outsourcedAccountantMutationValues,
  }
}

const formatAuthorizedSignerPerson = (pageValues) => {
  let authorizedSignerData = pageValues

  const authorizedSigner = pipe(removeUnsavedPersonKeys, mapObjIndexed(replaceDropdownAsString))(authorizedSignerData)
  const {
    _passportToggle: _,
    addressCity,
    addressCountryCode,
    addressPostalCode,
    addressState,
    addressStreet,
    addressStreetAdditional,
    authorizedSignerId: id,
    dobDisplay,
    email,
    passportExpiration,
    passportNumber,
    phoneNumber,
    recordType: __,
    shouldShowSsnError: ___,
    ssn,
    ssnDisplay,
    ...rest
  } = dobPluck(authorizedSigner)

  const passportNumberField = getPassportField(authorizedSigner)
  const ssnField = getSsnField(authorizedSigner)
  const formattedPhoneNumber =
    phoneNumber === '+1' ? null : parsePhoneNumber(phoneNumber || '', 'US')?.format('E.164') ?? ''

  const passportExpirationEpoch = dateStringToEpoch(passportExpiration)

  const authorizedSignerPersonFormData = {
    authorizedSigner: {
      ...rest,
      ...passportNumberField,
      ...ssnField,
      address: {
        city: addressCity,
        countryCode: addressCountryCode,
        postalCode: addressPostalCode,
        state: addressState,
        street: addressStreet,
        streetAdditional: addressStreetAdditional,
      },
      email: email || null,
      id,
      passportExpiration: epochToDate(passportExpirationEpoch),
      phoneNumber: formattedPhoneNumber,
    },
  }

  const formattedAuthorizedSignerPersonFormData = removeUndefinedValuesFromObject(authorizedSignerPersonFormData)
  return formattedAuthorizedSignerPersonFormData
}

export const prepCompanyOwners = (owners) => {
  return owners.reduce((acc, owner) => {
    const ssnField = getSsnField(owner)
    const passportNumberField = getPassportField(owner)
    Reflect.deleteProperty(owner, '_passportToggle')
    const formattedOwnerObj = {
      ...owner,
      ...ssnField,
      ...passportNumberField,
    }

    acc.push(formattedOwnerObj)
    return acc
  }, [])
}

const formatBeneficialOwners = (pageValues) => {
  const ownersList = Object.values(pageValues?.beneficialOwners)

  const owners = ownersList ?? []
  const beneficialOwners = reduce(prepBeneficialOwners, [], owners)
  return {
    beneficialOwners: beneficialOwners,
    hasBeneficialOwner: pageValues?.beneficialOwners?.majorityHolders === 'yes',
  }
}

export const formatPageValues = (pageName, pageValues) =>
  recursivelyRemoveBadCharacters(getAndFirstFormatPageValues(pageName, pageValues))

const getAndFirstFormatPageValues = (pageName, pageValues) => {
  switch (pageName) {
    case PageMutationKey.PAGE_SIGNUP: {
      return formatSignup(pageValues)
    }
    case PageMutationKey.PAGE_BUSINESS_INFO: {
      return formatBusinessInfo(pageValues)
    }
    case PageMutationKey.PAGE_FINANCE_INFO: {
      return formatFinanceInfo(pageValues)
    }
    case PageMutationKey.PAGE_AUTHORIZED_SIGNER: {
      return formatAuthorizedSignerPerson(pageValues)
    }
    case PageMutationKey.PAGE_COMPANY_OWNERS: {
      return formatBeneficialOwners(pageValues)
    }
    case PageMutationKey.PAGE_REVIEW_AND_VALIDATE:
    case PageMutationKey.PAGE_REVIEW_AND_SIGN: {
      let fraudPreventionData
      try {
        const bbInfo = window.ioGetBlackbox ? window.ioGetBlackbox : window?.IGLOO?.getBlackbox
        fraudPreventionData = bbInfo().blackbox
      } catch {
        fraudPreventionData = ''
      }
      const { creditApplicationFetchedAt, creditTerms, creditReportConsent, appVersion } = pageValues
      const isAppVersion2 = appVersion === 2

      const reviewAndSignVariables = {
        creditApplicationFetchedAt,
        creditTerms: isAppVersion2 ? creditReportConsent : creditTerms,
        fraudPreventionData,
      }

      return reviewAndSignVariables
    }

    default:
      return pageValues
  }
}

const recursivelyRemoveBadCharacters = (thing) => {
  if (!thing) {
    return thing
  } else if (typeof thing === 'string') {
    // eslint-disable-next-line no-control-regex
    return thing.replace(/\u0000/g, '')
  } else if (Array.isArray(thing)) {
    return thing.map((element) => recursivelyRemoveBadCharacters(element))
  } else if (typeof thing === 'object') {
    return Object.entries(thing).reduce((acc, [key, val]) => {
      return { ...acc, [key]: recursivelyRemoveBadCharacters(val) }
    }, {})
  } else {
    return thing
  }
}

const throwMutationNotFoundError = (pageName) => {
  throw new Error(`Mutation not found for ${pageName}`)
}

export const getPageMutationQuery = (pageName, isAuthorizedSigner = true, isMajorAccountChange = false) => {
  if (
    !isAuthorizedSigner &&
    [PageMutationKey.PAGE_REVIEW_AND_SIGN, PageMutationKey.PAGE_REVIEW_AND_VALIDATE].includes(pageName)
  ) {
    return RequestSignature
  }

  if (isAuthorizedSigner && pageName === PageMutationKey.PAGE_REVIEW_AND_SIGN && isMajorAccountChange) {
    return SubmitMajorAccountChange
  }

  return gqlMapping?.[pageName] ?? throwMutationNotFoundError()
}

export const getPageMutationVariables = (pageName, formValues, appId, isAuthorizedSigner = true) => {
  const pageValues = formValues?.[pageName === 'signup' ? 'businessInfo' : pageName]

  const scrubbedPageValues = removeKeyFromObj('__typename', pageValues)
  if (
    !isAuthorizedSigner &&
    [PageMutationKey.PAGE_REVIEW_AND_SIGN, PageMutationKey.PAGE_REVIEW_AND_VALIDATE].includes(pageName)
  ) {
    return {
      variables: {
        clientTimeZone: getClientTimezone(),
        creditApplicationId: appId,
      },
    }
  }

  return {
    variables: {
      ...formatPageValues(pageName, scrubbedPageValues),
      clientTimeZone: getClientTimezone(),
      creditApplicationId: appId,
    },
  }
}

export const handleMutationError = ({
  action,
  appId,
  error,
  eventName,
  navigateToDashboard,
  refetchFormValues,
  showErrorToast,
}) => {
  logError({
    attributes: {
      action: action,
      message: `Credit App ID: ${appId}`,
      result: error,
    },
    eventName: eventName,
  })

  const errorMessage = error?.networkError?.result?.errors?.[0]?.message || error?.graphQLErrors?.[0]?.message

  // We definitely need a better error handler once we return proper exception enums from back-end
  if (errorMessage?.startsWith('Invalid application status')) {
    logWarning({
      attributes: {
        action: 'redirect',
        message: appId,
        result: `Redirecting to the dashboard due to error: ${errorMessage}`,
      },
      eventName: eventName || 'PageSave',
    })

    navigateToDashboard()
  } else if (errorMessage?.startsWith('Credit application was submitted with stale information')) {
    logWarning({
      attributes: {
        action: 'refetch',
        message: appId,
        result: 'Refetching credit app since the user attempted to submit it with stale information',
      },
      eventName: eventName,
    })

    refetchFormValues()
    showErrorToast(
      <FormattedMessage
        defaultMessage='Changes were made to your application while you were reviewing. Please review your information again and submit.'
        id='sputnik.mutationUtils__lyBnII'
      />,
    )
  } else if (errorMessage?.includes('validation_error')) {
    showErrorToast(
      <FormattedMessage
        defaultMessage='Error occured while saving page: {error}'
        id='sputnik.mutationUtils__smcJ5g'
        values={{
          error: errorMessage.replace('Error: GraphQL error: validation_error: ', ''),
        }}
      />,
    )
  } else {
    showErrorToast(
      <FormattedMessage
        defaultMessage='Error occured while saving page'
        id='sputnik.mutationUtils__wJ31vE'
      />,
    )
  }
}
