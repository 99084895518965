import useDidMount from '@divvy-web/hooks.usedidmount'
import useNameFormatter from '@divvy-web/hooks.usenameformatter'
import { FormattedMessage } from '@divvy-web/i18n'
import Form from '@divvy-web/skylab.form'
import { TOAST_TYPE_DANGER, useToast } from '@divvy-web/skylab.toast'
import { css } from '@emotion/core'
import React, { useCallback, useState } from 'react'
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3'
import { useNavigate } from 'react-router-dom'
import { useCanary } from '@bill/canary.react'
import { getApplicantDataFromUPFromStorage, removeApplicantDataFromUPFromStorage } from '../../auth/localStorageUtils'
import { useAuth } from '../../auth'
import {
  InitCreditApplication_initCreditApplication_CreditApplication,
  InitCreditApplicationVariables,
  useInitCreditApplication,
} from '../../auth/gql/InitCreditApplication.gql'
import { AutoCompleteDisable } from '../../components/FormInputs'
import MiscPageWrapper from '../../components/MiscPageWrapper/MiscPageWrapper'
import { getMarketoValues } from '../../marketo'
import { PATHNAME_CONSTS } from '../../resources/constants'
import { removeNonNumericCharacters } from '../../utils/dataUtils'
import { logError, logInfo } from '../../utils/loggerUtils'
import { getClientTimezone } from '../../utils/timezoneUtil'
import { formatPhoneNumberFormField } from '../../components/utils'
import SignUpForm from './SignUpForm'
import signupValidations from './signupValidations'
import { getAccountingSoftwareItems } from './signUpConstants'

const SignUpView = () => {
  const [getClassName, makeTestId] = useNameFormatter('SignUp')
  const [isSubmitting, setIsSubmitting] = useState(false)
  const shouldShowStateBasedDisclosures = useCanary('state-based-disclosures')
  const showDangerToast = useToast(TOAST_TYPE_DANGER) as any
  const navigate = useNavigate()
  const { email, setEmail, requestApplicantToken } = useAuth()
  const applicantDataFromUP = getApplicantDataFromUPFromStorage()
  const decodedPathname = applicantDataFromUP ? JSON.parse(atob(applicantDataFromUP?.slice(1) || '')) : []

  const [initCreditAppMutation, { loading: isLoadingInitCreditApplication }] = useInitCreditApplication({
    onCompleted: (result) => {
      const { salesforceCreditId } =
        result?.initCreditApplication as InitCreditApplication_initCreditApplication_CreditApplication
      setEmail(email)

      logInfo({
        attributes: {
          action: 'initCreditApplication',
          result: 'Credit application initialized',
        },
        eventName: 'initCreditApplicationSuccess',
      })

      const handleInitCreditAppComplete = async (salesforceCreditId) => {
        await requestApplicantToken(salesforceCreditId)
        setIsSubmitting(false)
        removeApplicantDataFromUPFromStorage()
        navigate(
          { pathname: PATHNAME_CONSTS.DASHBOARD_PATH, search: '' },
          { state: { referrer: salesforceCreditId ? PATHNAME_CONSTS.AUTH_PATH : null } },
        )
      }
      handleInitCreditAppComplete(salesforceCreditId)
    },
    onError: (error) => {
      showDangerToast(
        <FormattedMessage
          defaultMessage='Error while initializing credit application.'
          id='sputnik.SignUpView__V2ViaC'
        />,
        {
          autoHideDelay: 5000,
          dataTestId: 'init-credit-app-error',
        },
      )
      setIsSubmitting(false)
      logError({
        attributes: {
          action: 'initCreditApplication',
          result: error,
          message: 'Error while initializing credit application.',
        },
        eventName: 'initCreditApplicationError',
      })
    },
  })

  const { executeRecaptcha } = useGoogleReCaptcha()

  const verifyReCaptcha = useCallback(
    async (action) => {
      if (!executeRecaptcha) return

      const token = await executeRecaptcha(action)

      logInfo({
        attributes: {
          action,
          result: 'Token retrieved',
        },
        eventName: 'ReCaptcha',
      })

      return token
    },
    [executeRecaptcha],
  )

  useDidMount(() => {
    logInfo({
      attributes: {
        result: 'Sign Up page loaded',
      },
      eventName: 'PageLoad',
    })
  })

  const billMarketoLeadParams = { marketoBillLeadInput: getMarketoValues() }

  const leadParams = billMarketoLeadParams?.marketoBillLeadInput
  const pact = leadParams?.pact
  const affiliateIdString = leadParams?.ltAffiliateId
  const affiliateId = (affiliateIdString && Number.parseInt(affiliateIdString)) || null
  const afidField = affiliateId ? { affiliateId } : {}
  const pactField = pact ? { partnerCode: pact } : {}

  const handleInitCreditApplication = async (values) => {
    logInfo({
      attributes: {
        action: 'initCreditApplication',
        result: 'Sign up button clicked',
      },
      eventName: 'initCreditApplicationCalled',
    })

    const {
      email,
      entityType,
      firstName,
      industry,
      lastName,
      legalBusinessName,
      naicsCode,
      numOfEmployees,
      phoneNumber: formattedPhoneNumber,
      accountingSoftware: accountingSoftwareValue,
    } = values

    const phoneNumber = removeNonNumericCharacters(formattedPhoneNumber)
    const mutationVariables = {
      applicant: {
        email,
        firstName,
        lastName,
        phoneNumber,
      },
      businessInfo: {
        entityType,
        industry,
        legalBusinessName,
        naicsCode,
        numOfEmployees: parseInt(numOfEmployees),
        // returns a key and a value
        accountingSoftware: getAccountingSoftwareItems(accountingSoftwareValue),
      },
      recaptchaToken: (await verifyReCaptcha('initApplication')) as string,
      ...afidField,
      ...pactField,
      clientTimeZone: getClientTimezone() as string,
      ...billMarketoLeadParams,
      appVersion: shouldShowStateBasedDisclosures ? 2 : 1,
    } as InitCreditApplicationVariables

    initCreditAppMutation({ variables: mutationVariables })
  }

  const handleSubmit = (values) => {
    logInfo({
      attributes: {
        action: 'handleSubmitSignUpView',
        result: 'Sign up button clicked',
      },
      eventName: 'SignUp',
    })
    setIsSubmitting(true)
    handleInitCreditApplication(values)
  }

  const findApplicantData = () => {
    const data = decodedPathname?.find((item) => item?.applicantData)

    const applicantData = data?.applicantData
    const isApplicantDataObjectEmpty =
      JSON.stringify(applicantData) === '{}' || Object?.keys(applicantData || {})?.length === 0
    const isApplicantDataObjectNullOrUndefined = applicantData === null || applicantData === undefined

    if (isApplicantDataObjectEmpty || isApplicantDataObjectNullOrUndefined) {
      return false
    } else {
      return applicantData
    }
  }

  const initialValues = () => {
    if (decodedPathname.length) {
      const applicantData = findApplicantData()
      const hasApplicantData = !!applicantData

      if (hasApplicantData) {
        const { accountingSoftware, companyName, email, firstName, lastName, phone, numberOfEmployees } =
          applicantData || {}
        return {
          email,
          firstName,
          lastName,
          phoneNumber: formatPhoneNumberFormField(phone),
          legalBusinessName: companyName,
          numOfEmployees: numberOfEmployees,
          accountingSoftware,
        }
      }
    }
    return Object.assign({}, { email })
  }

  return (
    <MiscPageWrapper
      pageName='signup'
      pageSubTitle={
        <FormattedMessage
          defaultMessage='Let’s get to know you'
          id='sputnik.SignUpView__1TF+wp'
        />
      }
      pageTitle={
        <FormattedMessage
          defaultMessage='Start spending smarter'
          id='sputnik.SignUpView__TbiUGv'
        />
      }
    >
      <Form
        className={getClassName('')}
        css={css`
          height: 100%;
        `}
        dataTestId={makeTestId('')}
        initialValues={initialValues()}
        validation={{
          ...signupValidations,
          accountingSoftware: {
            presence: {
              message: 'Please select an accounting software',
            },
          },
        }}
      >
        <SignUpForm
          isSubmitting={isLoadingInitCreditApplication || isSubmitting}
          verifyReCaptcha={verifyReCaptcha}
          onNextClick={handleSubmit}
        />
        <AutoCompleteDisable />
      </Form>
    </MiscPageWrapper>
  )
}

export default SignUpView
